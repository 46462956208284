* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
}

Footer {
	background-color: #1c1d1f;
	color: white;
}

.footer1 {
	background: url(../Images/bg3.png) no-repeat center center/cover;
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0.6;
	/* z-index: -1; */
}

.sub-footer {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	text-align: center;
	padding: 8% 0;
}

.footer1 ::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.5;
	z-index: -1;
}

.footer1 h2 {
	font-size: 3rem;
}

.footer1 p {
	font-style: italic;
	font-size: 1.2rem;
	margin-top: 3rem;
	color: white;
}

.contact {
	text-transform: uppercase;
	justify-content: center;
	align-items: center;
	padding: 10px 50px;
	font-weight: 500;
	border-radius: 15px;
	background-color: white;
	position: relative;
	box-shadow: 0 0 0 5px rgb(173 174 173);
	margin-top: 2rem;
}

.footer2 {
	display: flex;
}

.footer2>img {
	width: 7%;
	height: 10%;
	margin-left: 2%;
	margin-top: 4rem;
}

.school-links {
	display: flex;
	margin-top: 5rem;
	margin-bottom: 3rem;
}

.school-links a {
	color: white;
	text-decoration: none;
}

.school-links ul li {
	list-style: none;
	padding-top: 2rem;
}

.school-links h5 {
	font-weight: 700;
}

.footer3 {
	display: flex;

	padding: 2rem;
	font-size: 1.2rem;
	border-bottom: 1px solid white;
}

.CBSE {
	margin-left: 2rem;
}

.phone-school img {
	padding-right: 10px;
}

.Email-school img {
	padding-right: 10px;
}

.Address-School img {
	height: 6%;
	width: 10%;
	padding-left: 5px;
}

.Address-School {
	display: flex;
}

.Address-School a {
	margin-left: 0.9rem;
	padding-left: 9px;
	padding-right: 0;
	margin-right: 0px;
}

.footer4 {
	display: flex;
	justify-content: space-between;
	padding: 2rem;
}

.copyRight p {
	margin-left: 2rem;
	color: white;
}


.apps img {
	padding-right: 10px;
	padding-left: 10px;
}