* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Roboto", sans-serifs;
}

.Lc-Tc {
	margin-bottom: 20%;
}

.Lc-Tc p {
	margin-top: 40px;
	color: black;
	margin-bottom: 9px;
	font-weight: 600;
	font-size: 20px;
	font-weight: 500;
}