* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.images {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 30px;
	margin-bottom: 30%;
	padding-left: 10%;
	padding-right: 10%;
}
.Audi-Primary img {
	padding-top: 30px;
	padding-bottom: 50px;
	padding-right: 2rem;
}
.Lab {
	display: flex;
	margin-top: 30px;
	margin-left: -4%;
}
.Sports img {
	display: flex;
	padding-left: 5%;
}
.prePrimaryHeading img {
	padding-right: 2rem;
}

.prePrimaryHeading {
	color: black;
	font-weight: 600;
	margin-bottom: 0;
	margin-top: 0;
	margin-right: 77%;
}
.auditoriumHeading {
	color: black;
	font-weight: 600;
	margin-bottom: 0;
	margin-top: 0;
	margin-right: 77%;
}
.chemistryBioHeading {
	color: black;
	font-weight: 600;
	margin-bottom: 0;
	margin-top: 0;
	margin-right: 77%;
}
.sportsHeading {
	color: black;
	font-weight: 600;
	margin-bottom: 4px;
	margin-top: -3%;
	margin-left: 6%;
}
.LibraryHeading {
	color: black;
	font-weight: 600;
	margin-bottom: 3%;
	margin-top: 5%;
	margin-left: 2%;
}
