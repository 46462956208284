* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
h1 {
	font-size: 2.5rem;
	font-weight: 700;
	color: black;
	margin-bottom: 30px;
	margin-top: 7%;
}
p {
	color: #5f6181;
	font-size: 1rem;
}
.staff-list {
	background-color: black;
	color: white;
	padding: 10px 20px;
	border-radius: 5px;
	font-size: 1.4rem;
	font-weight: 500;
	margin-top: 20px;
	margin-bottom: 30%;
}
