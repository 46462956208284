* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}

.inquiry-heading {
	font-size: 1.5rem;
	font-weight: 700;
	color: black;
	margin-bottom: 30px;
	margin-top: 4%;
	margin-left: 5%;
}

.para {
	color: black;
	font-size: 1rem;
	margin-left: 5%;
}

.form-container2 {
	display: flex;
	flex-direction: column;
	margin-top: 5%;
	padding-top: 5%;
	padding-bottom: 5%;
	padding-left: 25px;
	padding-right: 25px;
	background-color: #eeeeef;
	margin-bottom: 30%;
	width: 50%;
	margin-left: 5%;
}

.information2 {
	margin-left: 60%;
	font-size: 1.5rem;
	color: black;
	margin-top: -85%;
	margin-bottom: 50%;
}

.information2 h3 {
	font-size: 1.8rem;
	/* padding-bottom: 2%; */
	font-weight: 700;
}

.information2 p {
	font-size: 1.5rem;
	color: black;
}

.Inquiry-submit {
	width: 40%;
	padding: 20px;
	margin: 0 auto;
	font-size: 1rem;
	border: none;
	margin-top: 2%;
	border-radius: 10px;
	background-color: #e67e22;
	color: white;
}

.add {
	margin-bottom: 10%;
}