* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.commitment {
	font-size: 1.5rem;
	font-weight: 500;
	margin-top: 5%;
	margin-bottom: 8%;
	color: #74747b;
}
.about {
	display: flex;
	float: left;
	font-size: 1.5rem;
	font-weight: 700;
	margin-top: 5%;
	margin-bottom: 8%;
}
.table {
	border-collapse: collapse;
	margin-bottom: 60%;
	color: #74747b;
}
