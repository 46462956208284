* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}

.Academics {
	margin-bottom: 50%;
	margin-left: 3%;
}

.offered h1 {
	font-size: 2.5rem;
	margin-top: 10%;
	margin-bottom: 5%;
	font-weight: 700;
	margin-right: 47rem;
}

.socialize {
	font-weight: 600;
	font-style: italic;
}

.methods {
	font-weight: 600;
	color: black;
}

.std {
	font-weight: 600;
	padding: 10px 0px;
	width: 95%;
}

.std a {
	text-decoration: none;
	color: black;
	display: inline;
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
}

/* .std p {
	color: black;
	font-weight: 600;
	margin-top: 1%;
} */

.V {
	font-weight: 600;
	text-align: left;
	border: 2px solid #e2e2e2;
	padding-top: 20px;
	text-decoration: none;
}

.V a {
	text-decoration: none;
	color: black;
}

.VI-VIII {
	margin-top: 2%;
	font-weight: 600;
	border: 2px solid #e2e2e2;
	padding-top: 20px;
	text-decoration: none;
}

.VI-VIII a {
	text-decoration: none;
	color: black;
}

.IX-X {
	margin-top: 2%;
	font-weight: 600;
	border: 2px solid #e2e2e2;
	padding-top: 20px;
	text-decoration: none;
}

.IX-X a {
	text-decoration: none;
	color: black;
}

.XI-XII {
	margin-top: 2%;
	font-weight: 600;
	border: 2px solid #e2e2e2;
	padding-top: 20px;
	text-decoration: none;
}

.XI-XII a {
	text-decoration: none;
	color: black;
}

.XI {
	margin-top: 1%;
	margin-bottom: 2%;
	padding-right: 10rem;
	font-weight: 600;
	text-align: center;
	padding-top: 20px;
	text-decoration: none;
}

.subjects {
	font-weight: 600;
	padding-left: 40px;
	text-decoration: none;
	color: black;
	padding-bottom: 1%;
}

.subject-data {
	font-weight: 600;
	text-decoration: none;
	padding-bottom: 4%;
}

.subject-data ul li {
	padding-bottom: 10px;

	list-style: none;
	text-decoration: none;
}

.subject-data ul li a {
	color: #4f518c;
}