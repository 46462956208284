* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}

.procedure {
	padding-top: 3%;
	display: flex;
	flex-direction: column;
	margin-left: 4%;
	margin-bottom: 2%;
}

.steps {
	margin-top: 1%;
	margin-bottom: 4%;
	margin-left: 4%;
}

.steps p {
	font-size: 1rem;
	font-weight: 500;
	padding: 10px;
	color: black;
	line-height: 1.5;
	margin-bottom: 0px;
	padding-left: 0px;
}

.Register-Inquiry {
	margin-top: 10%;
	margin-bottom: 10%;
	text-align: center;
	background-color: #0064fb;
	padding: 20px 30px;
	border-radius: 10px;
	align-self: flex-start;
}

.process-image {
	margin-bottom: 5%;
}