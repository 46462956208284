.accordion {
  background-color: #fff;
  border: 1px solid #E2E2E2;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  outline: none;
  transition: 0.4s;
  margin-bottom: 20px;
}

span {
  font-size: 20px;
  font-weight: 500;
  color: #240046;
}

button:focus {
  outline: none;
}

.accordion::before {
  content: '\002B';
  color: #777;
  font-weight: bold;
  margin-right: 15px;
}

.active::before {
  content: "\2212";
}

.panel {
  padding: 0 25px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.panel.open {
  max-height: 1000px;
  /* Set to a high value to ensure it fits any content */
}