* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.fees-structure {
	margin-top: 5%;
	justify-content: center;
	align-items: center;
}
.fess-data {
	text-align: center;
	align-items: center;
	margin-top: 30px;
	font-size: 1.5rem;
	color: #707070;
}
.fees-table {
	margin-top: 30px;
	width: 95%;
	align-items: center;
	margin-left: 2.5%;
}
.fees-table td,
th {
	padding: 10px;
	border: 1px solid #b9b9b9;
}
.fees-table th {
	color: #74747b;
	font-size: 1.2rem;
}
.fees-content {
	text-align: center;
	align-items: center;
	margin-top: 30px;
	font-weight: 600;
	font-size: 2rem;
	text-align: center;
	color: black;
	margin-bottom: 20px;
}
.Notes {
	margin-top: 5%;
	margin-left: 5%;
	font-size: 1.5rem;
	color: black;
}
.note {
	margin-top: 10px;
	margin-left: 5%;
	font-size: 1.5rem;
	color: black;
}
.FRC {
	margin-top: 50px;
	font-size: 1.5rem;
	margin-bottom: 40%;
	margin-left: 5%;
}
