* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.downloadTc {
	font-size: 2rem;
	font-weight: 700;
	color: black;
	margin: 0;
}

#number {
	margin-top: 2%;
	padding-bottom: 20px;
	padding-top: 20px;
	border: 1px solid #5f616d;
	border-radius: 6px;
	width: 100%;
	font-size: 1rem;
}

label {
	color: black;
	font-size: 1.3rem;
}

.Transfer-details {
	display: flex;
	flex-direction: column;
	color: #5f616d;
}

.dob {
	padding-top: 20px;
	color: #5f616d;
	display: flex;
	flex-direction: column;
	margin-top: 2%;
	width: 100%;
}

#date {
	margin-top: 2%;
	padding-bottom: 20px;
	padding-top: 20px;
	padding-left: 1%;
	color: #5f616d;
	border: 1px solid #5f616d;
	border-radius: 6px;
}

#date:focus {
	outline: none;
	box-shadow: 0 0 0 2px rgb(131, 220, 255);
}

.submit {
	width: 100%;
	padding: 10px;
	background-color: black;
	margin: 0 auto;
	color: white;
	font-size: 1rem;
	border: none;
	border-radius: 10px;
	margin-top: 5%;
	padding: 15px;
}

.transfer-data {
	margin-top: 3%;
	color: black;
	font-weight: 700;
}

.transfer-certificate {
	margin-top: 2%;
	border: 1px solid #5f616d;
	border-radius: 20px;
	text-align: center;
}

.details {
	font-size: 1rem;
}

.details td {
	padding: 10px;
	border: none;
	color: #5f616d;
}

.details-about {
	color: black;
	padding: 20px;
	margin-bottom: 10%;
}

.details-about td {
	padding: 10px;
	border: none;
	font-size: 1.5rem;
}