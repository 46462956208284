* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}

.header-banner .banner {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	color: white;
	overflow: hidden;
}

.header-banner .banner ::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: -1;
}

span {
	margin-top: 2%;
	color: white;
	font-size: 1.5rem;
	font-weight: 700;
}

.navbar {
	align-items: center;
	text-align: center;
	background-color: rgb(255, 255, 255 0.3);
	border: 2px solid white;
	border-radius: 50px;
	width: 83%;
	margin-left: 6%;
	margin-top: 3%;
	backdrop-filter: blur(10px);
}

.navbar ul {
	margin-bottom: 0 !important;
	padding-left: 0;
	display: flex;
}

.navbar li.Academics-btn:hover {
	background-color: black;
	color: white;
	border-radius: 30px;
}

.navbar li.Academics-btn ul.subject-dropdown :hover {
	background-color: black;
}

.navbar li.Academics-btn ul.subject-dropdown :hover a {
	color: white;
}

.navbar li.Admission-Buttons:hover {
	background-color: white;
	color: black;
	border-radius: 30px;
}

.navbar li.Admission-Buttons ul.Admission-dropdown li {
	padding: 5%;
}

.navbar li.Academics-btn ul :hover {
	background-color: white;
	color: black;
	border-radius: 0px 0px 10px 10px;
}

.navbar li.Admission-Buttons ul:hover {
	background-color: white;
	color: black;
	border-radius: 0px 0px 10px 10px;
}

.navbar li.Admission-Buttons ul.Admission-dropdown :hover {
	background-color: black;
}

.navbar li.Admission-Buttons ul.Admission-dropdown :hover a {
	color: white;
}

.navbar li.Admission-Buttons:hover {
	background-color: black;
	color: white;
}

.main_li:hover {
	border-radius: 30px;
}

ul li ul.dropdown li {
	display: block;
	padding-bottom: 20px;
	padding-top: 20px;
	padding-left: 20px;
	padding-right: 0px;
}

ul li ul.dropdown {
	width: 40%;
	display: none;
	position: absolute;
	z-index: 999;
	list-style: none;
	margin-top: 10px;
	margin-left: -3%;
	border-radius: 0 0 10px 10px;
	font-size: 10px;
}

ul li ul.subject-dropdown {
	width: 21%;
	display: none;
	position: absolute;
	z-index: 999;
	list-style: none;
	margin-top: 10px;
	margin-left: -3%;
	border-radius: 0 0 10px 10px;
	font-size: 10px;
}

ul li:hover ul.dropdown {
	display: block;
	background-color: white;
	text-decoration: none;
	border: none;
}

ul li ul.dropdown li a {
	overflow: hidden;
	color: black;
	text-decoration: none;
	font-size: 15px;
}

.navbar ul::before {
	content: "";
	display: block;
	height: 20%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -1;
	opacity: 0.5;
}

.navbar ul li {
	display: inline-block;
	list-style: none;
	text-align: left;
	padding: 10px 24px;
}

.navbar ul li a {
	text-align: left;
	text-decoration: none;
	color: white;
	font-size: 20px;
}

.navbar ul :hover {
	background-color: black;
	color: white;
}

.logo {
	padding-left: 80px;
	margin-top: 1%;
	text-align: center;
}

.logo img {
	margin-right: 50px;
}

.login {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-top: 2%;
	color: white;
	padding-top: 11px;
	padding-bottom: 11px;
	padding-left: 43px;
	padding-right: 30px;
	border-radius: 50px;
	margin-bottom: 5%;
	background-color: #1c1d1f;
	border: 1px solid white;
	cursor: pointer;
}

.login img {
	padding-bottom: 0px;
	margin-left: 2px;
}

.s-line {
	border: 1px solid white;
	border-radius: 20px;
	margin-left: 6%;
	height: 40px;
	padding: 8px 12px;
	color: white;
	background-color: (255, 255, 255 0.3);
	backdrop-filter: blur(5px);
	margin-top: 3%;
}

.s-line>p {
	color: white;
}

.heading {
	font-size: 5rem;
	margin-left: 40px;
	text-align: left;
	color: white;
	font-weight: 700;
}

.heading1 {
	font-size: 5rem;
	margin-top: 2%;
	text-align: left;
	color: white;
	margin-left: 25px;
	font-weight: 700;
}

.heading2 {
	font-size: 5rem;
	margin-top: 0%;
	text-align: left;
	color: white;
	margin-left: 25px;

	font-weight: 700;
}

.banner .btn {
	float: left;
	font-size: 1.25rem;
	margin-top: 15%;
	border-radius: 50px;
	border: 2px solid white;
	background-color: black;
	margin-top: 5%;
	transition: 0.5s;
}

.banner .btn:hover {
	transform: scale(1.05);
}

.s-line2 {
	margin-left: 25px;
	margin-top: 1%;
	color: white;
	font-size: 1.5rem;
}

.admission {
	position: relative;
	margin-left: 25px;
	margin-top: 3%;
	margin-bottom: 3%;
	text-align: center;
	color: white;
	padding: 10px;
	border-radius: 50px;
}