* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}
.contact-heading {
	font-size: 1.5rem;
	font-weight: 700;
	color: black;
	margin-bottom: 30px;
	margin-top: 10%;
	margin-left: 5%;
}
.para {
	color: black;
	font-size: 1rem;
	margin-left: 5%;
}

.form-container {
	display: flex;
	flex-direction: column;
	margin-top: 10%;
	padding-top: 5%;
	padding-bottom: 5%;
	padding-left: 25px;
	padding-right: 25px;
	background-color: #eeeeef;
	margin-bottom: 30%;
	width: 90%;
	margin-left: 5%;
}
.information {
	font-size: 1.5rem;
	color: black;
	margin-top: 18%;
}
h3 {
	font-size: 1.5rem;
	padding: 0;
	font-weight: 700;
}
.information p {
	margin-left: 1%;
	color: black;
	margin-bottom: 5%;
}
.contact-btn {
	width: 40%;
	padding: 20px;
	margin: 0 auto;
	font-size: 1rem;
	border: none;
	margin-top: 2%;
	border-radius: 10px;
	background-color: #00c838;
	color: white;
}
