* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.main {
	display: flex;
}

.about-school {
	font-size: 2.5rem;
	font-weight: 700;
	color: black;
	margin-left: 85px;
}

.School-Para {
	margin-top: 6%;
	font-size: 2.5rem;
	font-weight: 700;
	color: black;
}

.main img {
	margin-top: 4rem;
	border-radius: 50%;
}

.main p {
	color: black;
	margin-top: 4%;
	margin-bottom: 4%;
	font-weight: 500;
	font-size: 1.3rem;
}

.ResultContainer {
	background: url(../Images/bg2.png) no-repeat center center;
	background-size: cover;
	position: relative;
	height: 100vh;
	width: 101%;
	color: white;
	z-index: 1;
}

.result {
	background-color: #276fbf;
	top: 0%;
	bottom: 0%;
	width: 100%;
	height: 100vh;
	position: absolute;
	opacity: 0.8;
	z-index: -1;
}

.lastYear {
	display: flex;
	font-size: 2.5rem;
	font-weight: 700;
	margin-left: 5%;
	margin-top: 3%;
	z-index: 1;
}

.twelve {
	display: flex;
	font-size: 1.5rem;
	font-weight: 500;
	margin-left: 6%;
	margin-top: 3%;
}

.toppers {
	padding-left: 2rem;
	z-index: 1;
}

.Student-Result-Data {
	display: flex;
	justify-content: space-around;
	margin-top: 2%;
	z-index: 1;
}

.Student-Result-Data img {
	opacity: 1;
}

.stud {
	margin-top: 0%;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
	font-weight: 500;
	color: white;
}

.marks {
	margin-top: 1%;
	margin-bottom: 3%;
	font-size: 1.5rem;
	font-weight: 500;
	font-style: italic;
	color: white;
	z-index: 1;
}

.more {
	margin-top: 1%;
	width: 6rem;
	font-size: 1.5rem;
	font-weight: 200;
	color: #1d2939;
	border: 1px solid #1d2939;
	margin-right: 3%;
	border-radius: 50px;
	cursor: pointer;
	opacity: 2;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 12px;
}

.more img {
	background-color: #1d2939;
	border-radius: 50%;
}

.more p {
	color: #1D2939;
	font-weight: bold;
}

.ResultContainer {
	margin-bottom: 3%;
	z-index: 1;
}

.OurSchool {
	margin-bottom: 15%;
}

.image {
	display: flex;
	justify-content: center;
	align-items: center;
}

.image img {
	width: 100%;
	height: 100%;
}

.objectives {
	margin-left: 8rem;
	margin-bottom: 15%;
}

.objectives h3 {
	font-size: 3rem;
	font-weight: 700;
}

.informationSchool {
	font-size: 1.5rem;
	margin-left: 9rem;
}

.info1 {
	background-color: #2c3e50;
	width: 50%;
	color: white;
	padding: 2%;
	display: flex;
	float: left;
	border-radius: 30px;
	margin-top: -10rem;
	box-shadow: 0 0 0 5px#9db7d1;
	font-size: 19px;
}

.info2 {
	margin-left: 40%;
	width: 40%;
	margin-right: 7%;
	background-color: orange;
	color: white;
	padding: 2%;
	border-radius: 30px;
	margin-top: 1%;
	position: absolute;
	box-shadow: 0 0 0 5px rgb(255, 216, 144);
	font-size: 19px;
}

.info3 {
	background-color: #27ae60;
	width: 40%;
	color: white;
	padding: 2%;
	margin-top: 15%;
	float: left;
	border-radius: 30px;
	box-shadow: 0 0 0 5px #aaf9cc;
	font-size: 19px;
}